import { Box, Button, Grid, Typography } from "@mui/material";
import PageSectionContainer from "../../styles/PageSectionContainer";
import { colors } from "../../utils/colors";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { FormSchema } from "./validations";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import EmailIcon from "@mui/icons-material/Email";
import { CustomInputField } from "../../components/CustomInputField";
import styled from "styled-components";
import HoverIconButton from "../../components/HoverIconButton";
import { useState } from "react";
import { useCart } from "../../context/CartContext";
import items from "../../utils/items";
import { formatPrice } from "../../utils/helpers";
import emailjs from "emailjs-com";

const ServiceID = "service_tps5ugj";
const TemplateID = "template_f3sus4w";
const PublicKey = "O7JhNAmC_zcoPRvzn";

type FormSchemaType = yup.InferType<typeof FormSchema>;

export const StyledButton = styled(({ ...props }) => <Button {...props} />)`
  background-color: ${colors.blue} !important;
  color: ${colors.white} !important;
  margin-top: 1.5rem !important;
  text-transform: none !important;
  font-size: 1.2rem !important;
  width: 250px;
  height: 60px;
  &:hover {
    background-color: ${colors.blue};
    opacity: 0.7;
  }
  @media (max-width: 900px) {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
`;
const ContactPage = () => {
  const [cartAdded, setCartAdded] = useState(false);
  let total = 0;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(FormSchema),
  });

  const cart = useCart();

  const onSubmit = (data: FormSchemaType) => {
    navigate("/contact/success");
    localStorage.removeItem("cartItems");
    const form = document.getElementById("contact-form");
    emailjs.sendForm(ServiceID, TemplateID, form as HTMLFormElement, PublicKey);
  };
  return (
    <>
      <PageSectionContainer
        className="dip-from-black"
        display="flex"
        flexDirection="column"
        marginTop="4rem"
        justifyContent="center"
        alignItems="center"
      >
        <EmailIcon
          sx={{ fontSize: "6rem", color: colors.blue, marginBottom: "2rem" }}
        />
        <Box
          sx={{
            background: colors.white,
            width: "80%",
            padding: "15px",
            borderRadius: "20px",
            border: `solid 1px ${colors.blue}`,
          }}
        >
          <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  color={colors.font}
                  marginY="2rem"
                  align="center"
                >
                  {t("contact.heading")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.name_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.name?.message}
                  placeholder={t("contact.name_placeholder")}
                  type="text"
                  {...register("name")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.email_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.email?.message}
                  placeholder={t("contact.email_placeholder")}
                  type="text"
                  {...register("email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.phone_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.phone?.message}
                  placeholder={t("contact.phone_placeholder")}
                  type="text"
                  {...register("phone")}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  {t("contact.message_instruction")}
                </Typography>
                <CustomInputField
                  error={errors?.message?.message}
                  placeholder={t("contact.message_placeholder")}
                  type="text"
                  {...register("message")}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-start">
                {!cartAdded ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    onClick={() => setCartAdded(true)}
                    sx={{ cursor: "pointer" }}
                  >
                    <HoverIconButton />
                    <Typography>{t("contact.add_cart_to_message")}</Typography>
                  </Box>
                ) : (
                  <Box width="100%">
                    {cart.cartItems.map((item) => {
                      const currentItem = items.filter(
                        (curr) => curr.id === item.id
                      )[0];

                      total += currentItem.discounted_price * item.quantity;

                      return (
                        <>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            width="100%"
                          >
                            <Box display="flex" alignItems="center">
                              <img
                                src={currentItem.image}
                                style={{ width: "50px", marginRight: "1rem" }}
                              />
                              <Typography>
                                {item.quantity} x {t(currentItem.title)}
                              </Typography>
                            </Box>
                            <Box>
                              <Typography>
                                {formatPrice(
                                  item.quantity * currentItem.discounted_price
                                )}
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    <Box
                      width="100%"
                      marginTop="1rem"
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <Typography fontWeight={600}>
                        Total: {formatPrice(total)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <StyledButton type="submit" form="contact-form">
                  {t("contact.send")}
                </StyledButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </PageSectionContainer>
    </>
  );
};

export default ContactPage;
