import { TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { colors } from "../utils/colors";

interface Props {
  error?: string;
  [x: string]: any;
}

export const ErrorMessage = styled(({ ...props }) => (
  <Typography
    variant="subtitle1"
    fontSize="0.8rem"
    align="center"
    color={colors.syracuse}
    {...props}
  />
))``;

export const CustomInputField = React.forwardRef<HTMLInputElement, Props>(
  ({ error, ...props }, ref) => {
    return (
      <>
        <TextField
          variant="outlined"
          sx={{ width: "100%", wrap: "hard !important" }}
          ref={ref}
          {...props}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </>
    );
  }
);
