import RemoveShoppingCartOutlinedIcon from "@mui/icons-material/RemoveShoppingCartOutlined";
import PageSectionContainer from "../../styles/PageSectionContainer";
import { Box, Button, Typography } from "@mui/material";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const CartEmpty = () => {
  const { t } = useTranslation();
  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      paddingTop="4rem"
    >
      <RemoveShoppingCartOutlinedIcon
        sx={{
          width: "250px",
          height: "250px",
          marginBottom: "2rem",
          color: colors.grey,
        }}
      />
      <Typography
        variant="body1"
        align="center"
        fontSize="1.5rem"
        color={colors.grey}
        marginBottom="2rem"
      >
        {t("cart.empty_cart")}
      </Typography>

      <Button
        href="/"
        sx={{
          color: colors.white,
          backgroundColor: colors.blue,
          textTransform: "none",
          height: "48px",
          width: "200px",
          fontSize: "1rem",
          ":hover": {
            backgroundColor: colors.blue,
            opacity: "70%",
          },
        }}
      >
        {t("cart.continue_browsing")}
      </Button>
    </Box>
  );
};

export default CartEmpty;
