import { Box, Typography } from "@mui/material";
import PageSectionContainer from "../../styles/PageSectionContainer";
import { useTranslation } from "react-i18next";
import items from "../../utils/items";
import FeaturedItem from "../../components/FeaturedItem";
import { colors } from "../../utils/colors";

const Homepage = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageSectionContainer sx={{ marginTop: "6rem" }}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="h4" color={colors.title}>
            {t("home.featured")}
          </Typography>
          <Typography
            variant="h6"
            color={colors.grey}
            fontWeight={300}
            align="center"
          >
            {t("home.deals")}
          </Typography>
        </Box>
        <Box>{items.map((item) => FeaturedItem(item, t))}</Box>
      </PageSectionContainer>
    </>
  );
};

export default Homepage;
