export type ItemType = {
  id: number;
  title: string;
  description: string;
  image: string;
  original_price: number;
  discounted_price: number;
};

const items: ItemType[] = [
  {
    id: 1,
    title: "items.first.title",
    description: "items.first.description",
    image: "/assets/images/item1.webp",
    original_price: 4000,
    discounted_price: 3960.9,
  },
  {
    id: 2,
    title: "items.second.title",
    description: "items.second.description",
    image: "/assets/images/item2.webp",
    original_price: 24000,
    discounted_price: 23700,
  },
  {
    id: 3,
    title: "items.third.title",
    description: "items.third.description",
    image: "/assets/images/item3.jpg",
    original_price: 25000,
    discounted_price: 24790,
  },
  {
    id: 4,
    title: "items.fourth.title",
    description: "items.fourth.description",
    image: "/assets/images/item4.webp",
    original_price: 2390.9,
    discounted_price: 2238.9,
  },
  {
    id: 5,
    title: "items.fifth.title",
    description: "items.fifth.description",
    image: "/assets/images/item5.png",
    original_price: 13270,
    discounted_price: 12990,
  },
  {
    id: 6,
    title: "items.sixth.title",
    description: "items.sixth.description",
    image: "/assets/images/item6.jpg",
    original_price: 2160,
    discounted_price: 1980,
  },
];

export default items;
