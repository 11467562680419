import { Routes, Route } from "react-router-dom";
import Homepage from "../features/home-page/Homepage";
import CartPage from "../features/cart-page/CartPage";
import ContactPage from "../features/contact-page/ContactPage";
import ContactSuccess from "../features/contact-page/ContactSuccess";

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Homepage />} />
    <Route path="/cart" element={<CartPage />} />
    <Route path="/contact" element={<ContactPage />} />
    <Route path="/contact/success" element={<ContactSuccess />} />
  </Routes>
);

export default AppRoutes;
