import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CartItemType } from "../features/cart-page/types";
import items from "../utils/items";
import { formatPrice } from "../utils/helpers";
import { colors } from "../utils/colors";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useCart } from "../context/CartContext";

const CartItem = (props: { item: CartItemType; t: Function }) => {
  const { item, t } = props;
  const cart = useCart();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 600px)");
  const currentItem = items.filter(
    (currentItem) => item.id === currentItem.id
  )[0];
  return (
    <Paper elevation={3} sx={{ borderRadius: "10px" }}>
      <Grid container marginTop="3rem" padding="1rem">
        <Grid item md={4} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={currentItem.image}
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box
            height="100%"
            display="flex"
            justifyContent="space-between"
            marginRight="2rem"
          >
            <Box
              height="100% !important"
              width="100%"
              display="flex"
              flexDirection={
                isSmallScreen
                  ? isExtraSmallScreen
                    ? "column"
                    : "row"
                  : "column"
              }
              justifyContent="space-between"
            >
              <Box
                maxWidth={
                  isSmallScreen ? (isExtraSmallScreen ? "100%" : "80%") : "100%"
                }
              >
                <Typography variant="h6" color={colors.title}>
                  {t(currentItem.title)}
                </Typography>
                <Typography variant="body1" color={colors.grey}>
                  {t(currentItem.description)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          justifyContent="space-between"
          flexDirection={isSmallScreen ? "row" : "column"}
          alignItems="flex-end"
          paddingTop={isSmallScreen ? "1rem" : 0}
        >
          <IconButton
            sx={{ ":hover": { color: colors.red } }}
            onClick={() => cart.removeItem(item.id)}
          >
            <DeleteIcon />
          </IconButton>
          <Box>
            <ButtonGroup
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => cart.setQuantity(item.id, item.quantity - 1)}
              >
                <RemoveIcon />
              </IconButton>
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Typography>{item.quantity}</Typography>
              </Box>
              <IconButton onClick={() => cart.increaseQuantity(item.id)}>
                <AddIcon />
              </IconButton>
            </ButtonGroup>
            <Typography variant="h6">
              {formatPrice(item.quantity * currentItem.discounted_price)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CartItem;
