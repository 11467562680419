import { Box, Button, Typography } from "@mui/material";
import PageSectionContainer from "../../styles/PageSectionContainer";
import SuccessIcon from "../../components/SuccessIcon";
import { colors } from "../../utils/colors";
import { useTranslation } from "react-i18next";

const ContactSuccess = () => {
  const { t } = useTranslation();
  return (
    <PageSectionContainer>
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        marginTop="4rem"
      >
        <SuccessIcon />
        <Typography
          color={colors.grey}
          width="80%"
          align="center"
          marginTop="2rem"
        >
          {t("contact.contact_success")}
        </Typography>
        <Button
          href="/"
          sx={{
            marginTop: "2rem",
            color: colors.white,
            backgroundColor: colors.blue,
            textTransform: "none",
            height: "48px",
            width: "200px",
            fontSize: "1rem",
            ":hover": {
              backgroundColor: colors.blue,
              opacity: "70%",
            },
          }}
        >
          {t("contact.go_back_home")}
        </Button>
      </Box>
    </PageSectionContainer>
  );
};

export default ContactSuccess;
