import {
  AppBar,
  Box,
  Link,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../utils/colors";
import { supported_languages } from "../services/i18n";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import "flag-icons/css/flag-icons.min.css";
import TranslateIcon from "@mui/icons-material/Translate";

const NavbarContainer = styled(Box)`
  box-sizing: border-box;
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between !important;
  padding: 0.5rem 1rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  width: 100% !important;
  @media (min-width: 600px) {
    max-width: 720px !important;
  }

  @media (min-width: 900px) {
    max-width: 1236px !important;
  }
`;

const LanguagePickerContainer = styled(Box)`
  display: flex;
  border-radius: 100%;
  padding: 0.7rem;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: rgba(34, 31, 32, 0.1) !important;
  }
`;

const StyledAppBar = styled(({ ...props }) => <AppBar {...props} />)`
  position: sticky;
  right: 0px;
  top: 0px;
`;

const LinksContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LinkContainer = styled(Box)`
  margin: 0.5rem 1rem;
`;

const Navbar = () => {
  const { t } = useTranslation();
  const [elevation, setElevation] = useState(0);
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const currentLocation = useLocation();

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = (code: string) => {
    if (code) {
      i18next.changeLanguage(code);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      const newElevation = window.scrollY > 0 ? 1 : 0;
      setElevation(newElevation);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <StyledAppBar
        elevation={elevation}
        style={{
          backgroundColor:
            elevation === 1 ? colors.superiorityblue : colors.transparent,
        }}
      >
        <NavbarContainer>
          <Box>
            <Link underline="none" href="/">
              <Typography
                variant="h1"
                fontWeight={700}
                fontSize="3rem"
                color={elevation === 1 ? colors.white : colors.superiorityblue}
              >
                MRM
              </Typography>
            </Link>
          </Box>
          <LinksContainer>
            <LinkContainer>
              <Link underline="none" href="/" sx={{ cursor: "pointer" }}>
                <Typography
                  variant="body1"
                  color={elevation === 1 ? colors.white : colors.title}
                >
                  {t("navbar.home")}
                </Typography>
              </Link>
            </LinkContainer>

            <LinkContainer>
              <Link underline="none" href="/contact" sx={{ cursor: "pointer" }}>
                <Typography
                  variant="body1"
                  color={elevation === 1 ? colors.white : colors.title}
                >
                  {t("navbar.contact")}
                </Typography>
              </Link>
            </LinkContainer>

            <LinkContainer>
              <Link underline="none" href="/cart">
                <ShoppingCartOutlinedIcon
                  sx={{
                    color: elevation === 1 ? colors.white : colors.title,
                  }}
                />
              </Link>
            </LinkContainer>

            <LinkContainer>
              <LanguagePickerContainer onClick={handleMenuOpen}>
                {
                  <TranslateIcon
                    sx={{
                      color: elevation === 1 ? colors.white : colors.title,
                    }}
                  />
                }
              </LanguagePickerContainer>
              <Menu
                anchorEl={anchorElement}
                open={Boolean(anchorElement)}
                onClose={handleClose}
              >
                {supported_languages.map(({ code, name, country_code }) => (
                  <MenuItem key={code} onClick={() => handleMenuClose(code)}>
                    {name}
                  </MenuItem>
                ))}
              </Menu>
            </LinkContainer>
          </LinksContainer>
        </NavbarContainer>
      </StyledAppBar>
    </>
  );
};

export default Navbar;
