import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ItemType } from "../utils/items";
import { colors } from "../utils/colors";
import { useCart } from "../context/CartContext";
import { formatPrice } from "../utils/helpers";

const FeaturedItem = (featuredItem: ItemType, t: Function) => {
  const { increaseQuantity } = useCart();
  const isSmallScreen = useMediaQuery("(max-width: 900px)");
  const isExtraSmallScreen = useMediaQuery("(max-width: 600px)");
  return (
    <Paper elevation={3} sx={{ borderRadius: "10px" }}>
      <Grid container marginTop="3rem" padding="1rem">
        <Grid item md={4} xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={featuredItem.image}
              style={{ width: "200px", height: "200px" }}
            />
          </Box>
        </Grid>
        <Grid item md={4} xs={12}>
          <Box
            height="100%"
            display="flex"
            justifyContent="space-between"
            marginRight="2rem"
          >
            <Box
              height="100% !important"
              width="100%"
              display="flex"
              flexDirection={
                isSmallScreen
                  ? isExtraSmallScreen
                    ? "column"
                    : "row"
                  : "column"
              }
              justifyContent="space-between"
            >
              <Box
                maxWidth={
                  isSmallScreen ? (isExtraSmallScreen ? "100%" : "80%") : "100%"
                }
              >
                <Typography variant="h6" color={colors.title}>
                  {t(featuredItem.title)}
                </Typography>
                <Typography variant="body1" color={colors.grey}>
                  {t(featuredItem.description)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems={isExtraSmallScreen ? "flex-start" : "flex-end"}
                paddingTop={isExtraSmallScreen ? "0.5rem" : 0}
                flexDirection="column"
              >
                <Typography
                  variant="body2"
                  color="rgba(0, 0, 0, 0.3)"
                  sx={{
                    textDecoration: "line-through",
                  }}
                >
                  {formatPrice(featuredItem.original_price)}
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                  {formatPrice(featuredItem.discounted_price)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          md={4}
          xs={12}
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          paddingTop={isSmallScreen ? "1rem" : 0}
        >
          <Button
            href="/cart"
            sx={{
              backgroundColor: colors.superiorityblue,
              color: colors.white,
              minWidth: "150px",
              textTransform: "none",
              ":hover": {
                backgroundColor: colors.superiorityblue,
                opacity: 0.8,
              },
            }}
            fullWidth={isSmallScreen ? true : false}
            onClick={() => increaseQuantity(featuredItem.id)}
          >
            {t("home.add_to_cart")}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FeaturedItem;
