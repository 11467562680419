export const colors = {
  white: "rgb(255, 255, 255)",
  primary: "#CFCFCF",
  secondary: "#ECECEC",
  font: "#123449",
  button: "#19579f",
  button_hover: "#3D89E1",
  syracuse: "#D34E24",

  yellow: "rgb(249, 185, 52)",
  staryellow: "rgb(252, 186, 3)",
  orange: "rgb(255, 152, 0)",
  red: "rgb(239, 83, 80)",

  blue: "rgb(55, 125, 255)",
  lightblue: "rgba(55, 125, 255, 0.5)",
  extralightblue: "rgba(55, 125, 255, 0.043)",
  superiorityblue: "rgb(55, 125, 255)",

  extralightgrey: "rgba(70, 125, 227, 0.1)",
  lightgrey: "rgb(247, 250, 255)",
  emptycartgrey: "rgb(237, 241, 247)",
  mediumlightgrey: "rgba(0,0,0,0.12)",
  mediumdarkgrey: "rgb(34, 43, 69)",
  darkgrey: "rgb(24, 43, 69)",
  grey: "rgb(103, 119, 136)",
  shadow: "rgba(140, 152, 164, 0.25)",
  lightshadow: "rgba(140, 152, 164, 0.1)",

  title: "rgb(30,32,34)",
  transparent: "rgba(0,0,0,0)",
  hoverhighlight: "rgb(245, 245, 245)",

  green: "rgb(0, 255, 0)",
  successgreen: "rgb(76, 175, 80)",
};
