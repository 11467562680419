import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { colors } from "../utils/colors";

const HoverIconButton = ({ ...props }) => {
  const [hover, setHover] = useState(false);

  return (
    <IconButton
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover ? (
        <AddCircleOutlinedIcon sx={{ color: colors.blue }} />
      ) : (
        <AddCircleOutlineOutlinedIcon />
      )}
    </IconButton>
  );
};

export default HoverIconButton;
