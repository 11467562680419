import "./App.css";
import { Box, Paper } from "@mui/material";
import Navbar from "./components/Navbar";
import AppRoutes from "./routes";
import Footer from "./components/Footer";
import { CartProvider } from "./context/CartContext";

function App() {
  return (
    <CartProvider>
      <Paper elevation={0}>
        <Box>
          <header>
            <Navbar />
          </header>
          <AppRoutes />
          <footer>
            <Footer />
          </footer>
        </Box>
      </Paper>
    </CartProvider>
  );
}

export default App;
