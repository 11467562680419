import { useTranslation } from "react-i18next";
import CartItem from "../../components/CartItem";
import { useCart } from "../../context/CartContext";
import CartEmpty from "./CartEmpty";
import PageSectionContainer from "../../styles/PageSectionContainer";
import { Box, Button, Typography } from "@mui/material";
import items from "../../utils/items";
import { formatPrice } from "../../utils/helpers";
import { colors } from "../../utils/colors";

const CartPage = () => {
  const cart = useCart();
  let total = 0;
  cart.cartItems.forEach((item) => {
    const currentItem = items.filter((curr) => curr.id === item.id)[0];
    total += currentItem.discounted_price * item.quantity;
  });

  const { t } = useTranslation();
  return (
    <>
      <PageSectionContainer>
        {cart.cartItems?.length < 1 ? (
          <CartEmpty />
        ) : (
          <>
            {cart.cartItems.map((item) => (
              <CartItem item={item} t={t} />
            ))}
            <PageSectionContainer>
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="flex-end"
                flexDirection="column"
              >
                <Typography variant="h5" fontWeight="600">
                  Total: {formatPrice(total)}
                </Typography>
                <Button
                  sx={{
                    marginTop: "1rem",
                    width: "155px",
                    height: "40px",
                    textTransform: "none",
                    color: colors.white,
                    backgroundColor: colors.blue,
                    ":hover": {
                      backgroundColor: colors.blue,
                      opacity: 0.7,
                    },
                  }}
                  href="/contact"
                >
                  {t("contact.heading")}
                </Button>
              </Box>
            </PageSectionContainer>
          </>
        )}
      </PageSectionContainer>
    </>
  );
};

export default CartPage;
