import * as yup from "yup";

export const FormSchema = yup.object().shape({
  name: yup.string().required("Name is required!"),
  email: yup
    .string()
    .email("Please use a valid email address!")
    .required("Email is required!"),
  phone: yup.string(),
  message: yup.string(),
});
